/* @import url("https://fonts.googleapis.com/css?family=Noto Sans"); */
* {
  font-family: "Noto Sans";
}

/*=================================ALTERNATE-EMAIL-PAGE-STYLES================================ */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outsideAlternateEmail {
  display: flex;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 10;

  background: linear-gradient(
      124.25deg,
      rgba(94, 250, 254, 0.8) 0%,
      rgba(126, 173, 21, 0.4) 0%,
      rgba(152, 70, 70, 0.289774) 14.63%,
      rgba(181, 44, 129, 0) 31.83%
    ),
    linear-gradient(
      236.16deg,
      #554a00 0%,
      rgba(86, 109, 89, 0.44) 17.5%,
      rgba(28, 98, 183, 0) 30.6%
    ),
    #0f0617;
}

.alternateEmail {
  font-family: "Noto Sans";
  background-color: #1d1425;
  z-index: 10;
  padding-top: 40px;
  margin-top: 60px;
  border-radius: 20px;
  height: fit-content;
}

.zee5-logo {
  height: 85px;
  width: 85px;
  padding-right: 20px;
}

.starhub-logo {
  /* height: 64px; */
  /* width: 100px; */
}

.vector {
  padding-right: 10px;
}

.twologos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.signup-form-alternateEmail {
  max-width: 400px;
  flex: 1;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 20;
  padding: 20px;
}

.text-alternateEmail p {
  color: white;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  margin: 30px 0px;
}

.form-footer-alternateEmail {
  display: flex;
  justify-content: center;
  padding: 15px 0 15px;
}

.btn-alternateEmail {
  cursor: pointer;
  border: 1px solid #787a7b;
  background-color: transparent;
  height: 45px;
  width: 320px;
  color: #686b6c;
  font-size: 16px;
  font-family: "Noto Sans";

  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  margin: 40px;
}

/* ========================================================================================== */

/* ----------------------------------for input element sytt----------------------------------- */

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

.wrapper {
  background: transparent;
  padding: 30px;
  padding-left: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.wrapper .input-data {
  height: 40px;
  width: 100%;
  position: relative;
}
.wrapper .input-data input {
  height: 100%;
  width: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid silver;
  background-color: transparent;
  color: white;
}
.icon {
  height: 10px;
  width: 10px;
}

.input-data input:focus ~ label,
.input-data input:valid ~ label {
  transform: translateY(-20px);
  font-family: "Noto Sans";
  font-size: 15px;
  color: #a785ff;
}
.wrapper .input-data label {
  position: absolute;
  bottom: 10px;
  left: 0;
  color: #828282 !important;
  pointer-events: none;
  transition: all 0.3s ease;
  font-family: "Noto Sans";
}

.input-data .underline {
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
}
.input-data .underline:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #a785ff;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before {
  transform: scaleX(1);
}

@media only screen and (max-width: 700px) {
  .signup-form-alternateEmail {
    max-width: 100vw;
  }
  .alternateEmail {
    border-radius: 0px;
    /* margin-top: 0px; */
  }
}

/* ---------------------------------------------------------------------------------------- */

/* ========================================================================================== */

/*======================================LOGIN PAGE STYLES======================================== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hidden {
  display: none;
}

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
/* ===================================================================================== */

.outsideLogin {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;
  z-index: 10;

  background: linear-gradient(
      124.25deg,
      rgba(94, 250, 254, 0.8) 0%,
      rgba(126, 173, 21, 0.4) 0%,
      rgba(152, 70, 70, 0.289774) 14.63%,
      rgba(181, 44, 129, 0) 31.83%
    ),
    linear-gradient(
      236.16deg,
      #554a00 0%,
      rgba(86, 109, 89, 0.44) 17.5%,
      rgba(28, 98, 183, 0) 30.6%
    ),
    #0f0617;
}

.login {
  font-family: "Noto Sans";
  background-color: #1d1425;
  z-index: 10;
  padding-top: 60px;
  border-radius: 20px;
  height: fit-content;
}

.zee5-logo {
  height: 85px;
  width: 85px;
  padding-right: 20px;
}

.starhub-logo {
  /* height: 64px; */
  /* width: 100px; */
}

.vector {
  padding-right: 10px;
}

.icon {
  height: 2px;
  width: 2px;
  fill: #0f0617;
}
.twologos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  /* gap: 12px; */
}

.signup-form-login {
  max-width: 400px;
  flex: 1;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 20;
  padding: 20px;
}

.text-login p {
  color: white;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  margin: 30px 0px;
  font-weight: 500 !important;
  line-height: 20px;
}

.form-footer-login {
  display: flex;
  justify-content: center;
  padding: 15px 0 15px;
}
.btnlogin {
  cursor: pointer;
  border: 1px solid #828282;
  background-color: transparent;
  height: 45px;
  width: 320px;
  color: #828282;
  font-size: 16px;
  font-family: "Noto Sans";
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  margin: 40px;
}


/* ---------------------------for input field styles------------------------------- */

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

.wrapper {
  background: transparent;
  padding: 30px;
  padding-left: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.wrapper .input-data {
  height: 40px;
  width: 100%;
  position: relative;
}
.wrapper .input-data input {
  height: 100%;
  width: 100%;
  border: none;
  font-size: 17px;

  border-bottom: 2px solid silver;
  background-color: transparent;
  color: white;
}
.icon {
  height: 10px;
  width: 10px;
}
.input-data input:focus ~ label,
.input-data input:valid ~ label {
  transform: translateY(-20px);
  font-family: "Noto Sans";
  font-size: 15px;
  color: #a785ff;
}
.wrapper .input-data label {
  position: absolute;
  bottom: 10px;
  left: 0;
  color: #828282;
  pointer-events: none;
  transition: all 0.3s ease;
  font-family: "Noto Sans";
}
.input-data .underline {
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
}
.input-data .underline:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #a785ff;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before {
  transform: scaleX(1);
}

/* ------------------------------------------------------------------------------------------- */

/* ----------------------------------password eye button--------------------------------------- */

.wrapper span {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: white;
  cursor: pointer;
  display: none;
}

.wrapper input:valid ~ span {
  display: block;
}

/* --------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 700px) {
  .signup-form-login {
    max-width: 100vw;
  }

  .login {
    border-radius: 0px;
  }
}

/* ============================================================================================ */

/* =====================================REGISTER-PAGE-STYLES=================================== */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.registerWarningText {
  color: #828282;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 11px;
  margin: 0px 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.outsideRegister {
  display: flex;
  justify-content: center;
  min-height: 100vh;

  width: 100vw;
  z-index: 10;

  background: linear-gradient(
      124.25deg,
      rgba(94, 250, 254, 0.8) 0%,
      rgba(126, 173, 21, 0.4) 0%,
      rgba(152, 70, 70, 0.289774) 14.63%,
      rgba(181, 44, 129, 0) 31.83%
    ),
    linear-gradient(
      236.16deg,
      #554a00 0%,
      rgba(86, 109, 89, 0.44) 17.5%,
      rgba(28, 98, 183, 0) 30.6%
    ),
    #0f0617;
}

.register {
  font-family: sans-serif;
  background-color: #1d1425;
  z-index: 10;
  padding-top: 60px;
  border-radius: 20px;
  height: fit-content;
}

.border {
  width: 32px;
  height: 0px;

  transform: rotate(-90deg);

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.bgd {
  z-index: 1;
}

.zee5-logo {
  height: 85px;
  width: 85px;
  padding-right: 20px;
}

.starhub-logo {
  /* height: 64px; */
  /* width: 100px; */
}

.vector {
  padding-right: 10px;
}

.twologos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.signup-form-register {
  max-width: 400px;
  flex: 1;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 20;
  padding: 20px;
}

.text-register {
  color: white;
  text-align: center;
  font-family: sans-serif;
  font-size: 14px;
  margin: 30px 0px;
}

.form-footer-register {
  display: flex;
  justify-content: center;
  padding: 15px 0 15px;
}
.register-btn {
  cursor: pointer;
  border: 1px solid #787a7b;
  background-color: transparent;
  height: 45px;
  width: 320px;
  color: #828282;
  font-size: 16px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  margin: 40px;
}

label {
  color: white;
  font-size: 12px;
  font-family: sans-serif;
  margin-bottom: 10px;
}
/*------------------------------------------------------------------------------------------- */

/* -----------------------------------for input element sytt--------------------------------- */

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

.wrapper {
  background: transparent;
  padding: 30px;
  padding-left: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.wrapper .input-data {
  height: 40px;
  width: 100%;
  position: relative;
}
.wrapper .input-data input {
  height: 100%;
  width: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid silver;
  background-color: transparent;
  color: white;
}
.icon {
  height: 10px;
  width: 10px;
}
.input-data input:focus ~ label,
.input-data input:valid ~ label {
  transform: translateY(-20px);
  font-size: 15px;
  color: #a785ff;
  font-family: "Noto Sans";
}
.wrapper .input-data label {
  position: absolute;
  bottom: 10px;
  left: 0;
  /* color: grey; */
  color: #828282;
  pointer-events: none;
  transition: all 0.3s ease;
  font-family: "Noto Sans";
}
.input-data .underline {
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
}
.input-data .underline:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #a785ff;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before {
  transform: scaleX(1);
}

/* -------------------------------------------------------------------------------------------- */

/* ---------------------------------password eye button---------------------------------------- */

.wrapper span {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: white;
  cursor: pointer;
  display: none;
}

.wrapper input:valid ~ span {
  display: block;
}

/* --------------------------------------REGISTER PAGE MEDIA QUERY--------------------------- */

@media only screen and (max-width: 700px) {
  .signup-form-register {
    max-width: 100vw;
  }

  .register {
    border-radius: 0px;
  }
  .registerWarningText {
    font-size: 10px;
  }
}

/* =================================REGISTER-PAGE-STYLES-END===================================== */

/* =================================SINGLE-BUTTON-PAGE-STYLES====================================*/

.outsideAuthenticateButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 10;

  background: linear-gradient(
      124.25deg,
      rgba(94, 250, 254, 0.8) 0%,
      rgba(126, 173, 21, 0.4) 0%,
      rgba(152, 70, 70, 0.289774) 14.63%,
      rgba(181, 44, 129, 0) 31.83%
    ),
    linear-gradient(
      236.16deg,
      #554a00 0%,
      rgba(86, 109, 89, 0.44) 17.5%,
      rgba(28, 98, 183, 0) 30.6%
    ),
    #0f0617;
}

.singleAuthenticateButton {
  font-family: sans-serif;
  background-color: #1d1425;
  z-index: 10;
  padding-top: 60px;
  border-radius: 20px;
}

.vector {
  padding-right: 10px;
}
.zee5-logo {
  height: 62.22px;
  width: 75px;
  padding-right: 20px;
}
.starhub-logo {
  /* height: 64px; */
  /* width: 100px; */
}
.twologos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.signup-form-singleButton {
  max-width: 350px;
  flex: 1;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 20;
  /* padding: 20px; */
  /* display: flex; */
}

.form-footer-authenticate {
  display: flex;
  justify-content: center;
  padding: 0 0 15px;
}

.btnAuthenticate {
  cursor: pointer;
  border: 1px solid #787a7b;
  background-color: #8230c6;
  color: #ffffff;
  height: 45px;
  width: 320px;
  font-size: 16px;
  font-family: "Noto Sans";
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  margin: 20px;
  margin-left: 25px;
}



@media only screen and (max-width: 700px) {
  .signup-form-singleButton {
    max-width: 100vw;
  }
  .singleAuthenticateButton {
    border-radius: 0px;
  }
}

/* ====================================================================================== */

/* ============================SUBSCRIPTION-STATUS-PAGE-STYLES=================================== */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.radioContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.radioYes {
  margin-top: 20px;
}
.radioNo {
  margin-top: 20px;
}

.outsideStatusPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 10;

  background: linear-gradient(
      124.25deg,
      rgba(94, 250, 254, 0.8) 0%,
      rgba(126, 173, 21, 0.4) 0%,
      rgba(152, 70, 70, 0.289774) 14.63%,
      rgba(181, 44, 129, 0) 31.83%
    ),
    linear-gradient(
      236.16deg,
      #554a00 0%,
      rgba(86, 109, 89, 0.44) 17.5%,
      rgba(28, 98, 183, 0) 30.6%
    ),
    #0f0617;
}

.statusPage {
  font-family: sans-serif;
  background-color: #1d1425;
  z-index: 10;
  padding-top: 60px;
  border-radius: 20px;
}
.twologos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.zee5-logo {
  height: 85px;
  width: 85px;
  padding-right: 20px;
}

.starhub-logo {
  height: 75px !important;
   width: 62px !important;
}

.vector {
  padding-right: 10px;
}

.signup-form-status {
  max-width: 400px;
  flex: 1;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 20;
  padding: 20px;
}

.text-status p {
  color: white;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  margin: 30px 0px;
}

.form-footer-status {
  display: flex;
  justify-content: center;
  padding: 15px 0 15px;
}

.btnStatus {
  cursor: pointer;
  border: 1px solid #787a7b;
  background-color: #8230c6;
  height: 45px;
  width: 320px;
  color: #ffffff;
  font-size: 18px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  margin: 40px;
  font-family: "Noto Sans";
}

.radioContainer label {
  color: white;
  font-size: 17px;
  margin-bottom: 10px;
  font-family: "Noto Sans";
}

input[type="radio"] {
  margin-right: 20px;
}

.hidden {
  opacity: 0 !important;
  cursor: default;
}

@media only screen and (max-width: 700px) {
  .signup-form-status {
    max-width: 100vw;
  }
  .radioContainer label {
    color: white;
    font-size: 15px;
    font-family: "Noto Sans";

    margin-bottom: 10px;
    text-align: center;
  }
  .text-status p {
    color: white;
    text-align: center;
    font-family: "Noto Sans";
    font-size: 15px;
    margin: 30px 0px;
  }
  .statusPage {
    border-radius: 0px;
  }
}

/* ========================================================================================== */

/* ===================================SUBSCRIPTION STYLES====================================== */

* {
  margin: 0;
  padding: 0;
}

.hidden {
  display: none !important;
}

hr {
  size: 5px;
  color: aqua;
}
.subscribe-label-value-num {
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.6363636364em;
  color: #787a7b;
}
.subscribe-label-value-text {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.6363636364em;
  color: #787a7b;
}
.subscribe-container-item .smallText {
  font-size: 12px;
  color: #787a7b;
}
.subscribe--main:not(:last-child) {
  border-bottom: 1px solid rgba(120, 122, 123, 0.1);
}

.subscribe-label-mainHeader {
  font-size: 16px;
  color: #ffffff;
}

.subscribe-label-sideHeader {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6363636364em;
  color: #ffffff;
}

.subscribe-label-value {
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.6363636364em;
  color: #787a7b;
}

.subscribe-msg {
  color: white;
  font-size: 18px;
  text-align: center;
  font-family: Noto Sans;
  font-weight: 700;
  align-self: center;
}

.zee5-logo {
  align-self: center;
  justify-self: center;
  z-index: 20;
  height: 85px;
  width: 85px;
  padding-right: 20px;
}
.logoAndText {
  display: flex;
  flex-direction: column;
  height: 25%;
  z-index: 30;
  width: 42vw;
  justify-content: center;
  align-content: center;
  margin-top: 200px;
}

label {
  color: white;
  font-size: 12px;
  font-family: sans-serif;
  margin-bottom: 10px;
}

.subscribe {
  font-family: sans-serif;
  background-color: #0f0617;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding-bottom: 20px;
  max-width: 580px;
  width: 100%;
  max-height: 485px;
  margin-top: 70px;
}

.subscribe {
  display: flex;
  flex: 1;
  padding: 5px;
  font-family: sans-serif;
  background-color: #1d1425;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 10;
  justify-content: center;
  height: 75%;
  border-radius: 20px;
}

.subscribe-container {
  width: 90%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  flex: 1;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 10;
  padding: 0 10px;
}
.align-self {
  align-self: baseline !important;
}
.center {
  text-align: center;
}
.subscribe-container-item:not(:first-child) {
  text-align: center;
}
.subscribe-container-item {
  padding: 10px 10px 10px 10px;
  background-color: #1d1425;
  margin-left: 2px;
  width: 33.3%;
  align-self: center;
  justify-self: center;
}

.sub-paragragh {
  font-size: 7px;
  color: white;
}

.subscribe--main {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-items: center;
}
.value {
  justify-content: center;
}

.subscribe-alt-mail {
  margin-bottom: 20px;
}

.btn-subscribe {
  cursor: pointer;
  border: 1px solid #787a7b;
  margin-left: 0px;
  background-color: transparent;
  height: 40px;
  width: 100px;
  color: #686b6c;
  font-size: 18px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}

.btn-subscribe:hover {
  background-color: white;
  color: #090000;
}

.outsideSubscribe {
  display: flex;
  justify-content: center;

  height: 100vh;
  width: 100%;
  z-index: 10;
  align-content: center;
}
.topText {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.outsideSubscribe {
  background: linear-gradient(
      124.25deg,
      rgba(94, 250, 254, 0.8) 0%,
      rgba(126, 173, 21, 0.4) 0%,
      rgba(152, 70, 70, 0.289774) 14.63%,
      rgba(181, 44, 129, 0) 31.83%
    ),
    linear-gradient(
      236.16deg,
      #554a00 0%,
      rgba(86, 109, 89, 0.44) 17.5%,
      rgba(28, 98, 183, 0) 30.6%
    ),
    #0f0617;
}
.red {
  height: 24px;
  width: 24px;
}
/* --------------------------------MEDIA QUERY FOR SUBSCRIPTION--------------------------------- */

@media only screen and (max-width: 700px) {
  .row {
    flex-direction: column;
  }
  .signup-form {
    max-width: 100%;
    flex: 1;
    border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
 
  .subscribe-container {
    max-width: 100%;
    flex: 1;
    margin-top: 0;
    margin-bottom: 0;
  }

  .subscribe-container-item {
    padding: 2px 2px 2px 2px;
    margin-left: 2px;
  }
  .subscribe-label {
    align-self: center;
    padding: 5px 5px 5px 5px;
    size: 35px;
  }

  .subscribe-label {
    align-self: center;
  }

}
/* @media only screen and (max-width: 700px) {
  .form-header h1 {
    font-size: 15px;
  }
} */

/* --------------------------------------------------------------------------------------------- */

/* ============================================================================================== */

/* ======================SUBSCRIPTION--STATUS--FALSE--STYLES=================================== */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.text-false-status {
  color: white;
  text-align: center;
  font-family: "Noto Sans";

  font-size: 17px;
  margin: 30px 0px;
}

.outside-false-status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 10;

  background: linear-gradient(
      124.25deg,
      rgba(94, 250, 254, 0.8) 0%,
      rgba(126, 173, 21, 0.4) 0%,
      rgba(152, 70, 70, 0.289774) 14.63%,
      rgba(181, 44, 129, 0) 31.83%
    ),
    linear-gradient(
      236.16deg,
      #554a00 0%,
      rgba(86, 109, 89, 0.44) 17.5%,
      rgba(28, 98, 183, 0) 30.6%
    ),
    #0f0617;
}

.false-status {
  font-family: sans-serif;
  background-color: #1d1425;
  z-index: 10;
  padding-top: 60px;
  border-radius: 20px;
}

.vector {
  padding-right: 10px;
}
.zee5-logo {
  height: 62.22px;
  width: 75px;
  padding-right: 20px;
}
.starhub-logo {
  /* height: 64px;
  width: 100px; */
}
.twologos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.signup-false-status {
  max-width: 400px;
  width: 380px;
  height: 200px;
  flex: 1;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 20;
  padding: 20px;
}

@media only screen and (max-width: 700px) {
  .signup-false-status {
    max-width: 100vw;
  }
  .logoAndText{
    width: 100% !important;
    margin: auto 40px;
  }
}

/* ======================================================================================== */
/* allow aaccc */
.allow-access {
  display: flex;
  flex-direction: row;
  padding: 30px;
  padding-top: 5px;
  padding-left: 40px;
  align-items: flex-start;
}
.permissions-text {
  align-self: center;
  font-size: 12px;
  color: #787a7b;
}
.allow-checkbox  , .checkbox-above21{
  margin-right: 10px;
  margin-top: 5px;
}
.policies,
.terms {
  color: #787a7b;
  background-color: none;
  cursor: pointer;
}

/* //inbetween-line */
.inbetween-line{
  border : 1px solid #ffffff;
  height: 40px;
  margin-left: 10px;
}
.zee5-logo{
  margin-left: 20px;
}
.confirmation-header{
color: #fff;
margin: 20px;
/* margin-left: 25px; */
font-weight: 400;
font-size: 16px;
text-align: center;
}
.confirmation-details{
  color: #fff;
margin: 20px;
margin-left: 25px;
font-weight: 400;
font-size: 12px;
/* text-align: center; */
}
.middleText{
  margin-top: 20px;
 color: #ffffff;
 text-align: center;
}
.zee5-link{
  /* text-decoration: none; */
  color : #ffffff;

}
.footerBtn{
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;

}
.donwloadonthe{
  font-size: 10px;

}
.appstore{
  font-size: 14px;
}
.footerapple{
  display: flex;
  color: #fff;
  /* border : 1px solid #ccc; */
  padding: 5px 5px;
  text-decoration: none;
  margin-right: 20px;
  /* height: ; */
  align-items: center;
  align-content: center;
  justify-content: center;
}
.appleicon{
   height: 40px;
   /* width: 100px; */
}
.playicon{
  height: 40px;
  /* width: 100px; */
  align-self: center;
  justify-self: center;
  margin-right: 5px;
}
.alternative-suggestion{
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.false-link{
  color : #fff;
  text-decoration: underline;
}
.false-link:hover{
  color: #fff;
}
.false-text{
  margin-top: 10px;
  font-size: 14px;
}
.false-header{
  font-size: 14px;
}